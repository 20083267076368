import React from 'react';

export function Homepage() {
  return (
    <div>
      <h1>Welcome to My Homepage</h1>
      <p>This is a simple React homepage.</p>
    </div>
  );
}

export default Homepage;
