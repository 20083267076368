import React from 'react';

export function Voting() {
  return (
    <div>
      <h1>Welcome to My Voting Page</h1>
      <p>This is a simple page for voting on others courses.</p>
    </div>
  );
}

export default Voting;
