import React from 'react';
// import Sketch from 'react-sketch';

function DrawingCanvas({ image }) {
  return (
    <div className="canvas-container">
      {/* <Sketch
        value={[]}
        tool={Sketch.Tool.Pencil}
        width="100%"
        height={400}
        onChange={() => {}}
      /> */}
    </div>
  );
}

export default DrawingCanvas;
